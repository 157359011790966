.accountsmain {
    padding-top: 136px;

    .accounthead {
        font-size: 90px;
        font-weight: 900;
        line-height: 90px;
        letter-spacing: -0.04em;
        text-align: center;
        color: #FFFFFF;
        max-width: 1113px;
        width: 100%;
        margin: 0 auto 34px;
    }

    .accountscards {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        overflow: auto;
        padding: 0px 180px;

        &::-webkit-scrollbar {
            display: none !important;
        }

        .inneraccountcard {
            min-width: 426px;
            height: 437px;
            padding: 45px;
            border-radius: 20px;
            background: #22612D;

            .accountcardhead {
                color: #FFFFFF;
                font-size: 38.99px;
                font-weight: 900;
                line-height: 38.99px;
                letter-spacing: -0.04em;
                text-align: left;
                margin-top: 21px;
                margin-bottom: 16px;
            }

            .accountcardpara {
                color: #FFFFFF;
                font-size: 21.84px;
                font-weight: 500;
                line-height: 23.65px;
                letter-spacing: -0.04em;
                text-align: left;
                padding-bottom: 16px;
            }

            .accountcardbtn {
                background: #FF9900;
                padding: 13px 19px;
                border-radius: 24px;
               color: #FFFFFF;
                font-size: 17.21px;
                font-weight: 900;
                line-height: 17.73px;
                letter-spacing: -0.04em;
                text-align: left;
                border: none;
                white-space: nowrap;
            }
        }
    }
}
@media(max-width:1250px){
    .accountsmain .accountscards{
        padding: 0px 15px 0px;
    }
    .accountsmain .accounthead{
        padding: 0px 15px;
    }
}
@media(max-width:991px){
    .accountsmain{
        padding-top: 50px;
    }
    .accountsmain .accounthead{
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .accountsmain .accountscards .inneraccountcard{
        padding: 30px;
        min-width: 300px;
        height: 400px;
    }
    .accountsmain .accountscards .inneraccountcard .accountcardhead{
        font-size: 30px;
        line-height: 30px;
    }
    .accountsmain .accountscards .inneraccountcard .accountcardpara{
        font-size: 20px;
        line-height: 20px;
    }
    .accountsmain .accountscards .inneraccountcard .accountcardbtn{
        font-size: 12px;
        font-weight: 700;
        padding: 10px 15px;
    }
}