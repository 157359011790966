.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px 30px;
  border-radius: 20px;
  width: 800px;
  max-width: 90%;
  position: relative;
}

.popup-heading {
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.popup-paragraph {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
}

.popup-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.popup-row {
  display: flex;
  align-items: center;
  gap: 15px;
}

.popup-row label {
  flex: 1;
  font-weight: bold;
}

.popup-row input {
  width: 100%;
  height: 70.08px;
  padding: 7.36px;
  border-radius: 32px;
  border: 1.47px solid #ccc;
  opacity: 0.8;
  box-sizing: border-box;
  flex: 1;
}

.loader {
  text-align: center;
  font-size: 1.5rem;
  padding: 2rem;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-buttons .popup-submit-button,
.popup-buttons .popup-skip-button {
  width: 48%;
  height: 50px;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}

.popup-buttons .popup-submit-button {
  background-color: #ff9800;
  border: none;
  color: white;
}

.popup-buttons .popup-submit-button:hover {
  background-color: #e68900;
}

.popup-buttons .popup-skip-button {
  background-color: transparent;
  border: 2px solid #ff9800;
  color: #ff9800;
}

.popup-buttons .popup-skip-button:hover {
  background-color: #ff9800;
  color: white;
}
