.form-body-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .form-body {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .form-group {
      margin-bottom: 1rem;
    }

    .submit-button {
      display: block;
      width: 100%;
      margin: 1rem auto 0;
      background-color: #ff9800; // Orange background
      border-color: #ff9800; // Orange border
      border-radius: 30px;
      padding: 10px 15px;
      font-weight: bold;
      color: white;
      text-transform: uppercase;

      &:hover {
        background-color: #e68900; // Darker orange on hover
        border-color: #e68900; // Darker orange border on hover
      }
    }
  }
}

.radio-buttons {
  display: flex;
  gap: 10px; /* Adjust the space between the buttons */
}

.radio-buttons input[type="radio"] {
  display: none;
}

.radio-buttons .radio-label {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 20px;
  background-color: #22612d;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.radio-buttons input[type="radio"]:checked + .radio-label {
  background-color: white;
  color: #22612d;
}

.radio-buttons .radio-label:hover {
  background-color: #1e4f27; /* Adjust the hover color as needed */
}

.form-label {
  color: white; // Label text color
  font-weight: bold;
}

.form-control {
  border-radius: 50px;
  padding: 10px 15px;
  border: 2px solid #fff;
  background-color: #287636;
  color: white;
  box-shadow: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #ffa500;
    background-color: #2e7d32;
    box-shadow: none;
  }
}
