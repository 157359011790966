.main-navbar {
  border: none;
  background: transparent;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999;
  padding: 0px;

  .navbar-toggler {
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    margin: 0;
    padding: 0;
  }

  .left-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding-left: 20px;

    .mainlink {
      font-size: 15px;
      font-weight: 500;
      line-height: 16.73px;
      letter-spacing: -0.04em;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }
  }

  .navbar-nav {
    padding: 0px;
    margin: 0px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 18px;
    .navbtn {
      border: 1px solid #ffffff;
      padding: 11px 17px 11px 17px;
      border-radius: 48px;
      font-size: 15px;
      font-weight: 500;
      line-height: 16.73px;
      letter-spacing: -0.04em;
      color: #ffffff;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav-item {
      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 16.73px;
        letter-spacing: -0.04em;
        text-align: left;
        color: #ffffff;
        padding: 0;

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%);

        .parent-menu {
          box-shadow: 0px 4px 4px 0px #00000040;
          border-radius: 16px;
          background: #ffffff;
          padding: 12px;
          margin-top: 18px;
          overflow: hidden;
          width: 216px;
          cursor: pointer;

          a {
            border: none;
            background: #ff9900;
            padding: 11px 17px 11px 17px;
            border-radius: 48px;
            font-size: 15px;
            font-weight: 700;
            line-height: 16.73px;
            letter-spacing: -0.04em;
            color: #ffffff;
            text-align: center;
            transition: 0.5s linear;
            margin-bottom: 18px;
            white-space: wrap;

            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .dropdown-menu {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease;
    visibility: hidden;
    background-color: transparent;
  }

  .dropdown:hover .dropdown-menu {
    max-height: 350px;
    visibility: visible;
    pointer-events: visible;
  }
}

.submit-button {
  display: block;
  width: 100%;
  margin: 1rem auto 0;
  background-color: #ff9800; // Orange background
  border-color: #ff9800; // Orange border
  border-radius: 30px;
  padding: 10px 15px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.mobile-menu-offcanvas {
  background: #000;
  min-width: 100%;

  .offcanvas-header {
    border-bottom: 1px solid #1e1d1d;
    background: #000;
    padding: 22px 20px;

    .btn-close {
      // background: url("../../../assets/close-icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 0;
      opacity: 1;
      box-shadow: none;
    }
  }

  .offcanvas-body {
    padding: 0;

    .navbar-nav {
      .dropdown {
        a {
          border-top: 1px solid #1e1d1d;
          background: #000;
          padding: 22px 20px;
          color: #9a9a9a;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.014px;
          text-transform: uppercase;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &[aria-expanded="true"] {
            color: #fff;

            svg {
              transform: rotate(180deg);
            }
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          padding: 0;
          position: static;
          transform: unset;
          width: 100%;

          &.show {
            padding: 0;
            position: static !important;
            transform: unset !important;
            width: 100%;
          }

          a {
            color: #9a9a9a;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            padding: 18px 35px;
            background: #090909;
            border-bottom: 1px solid #141414;
          }
        }
      }

      .drop-arrow {
        border-top: 1px solid #1e1d1d;
        background: #000;
        padding: 22px 20px;
        color: #9a9a9a;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.014px;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        a {
          color: #9a9a9a;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.014px;
          text-transform: uppercase;
        }

        .dropdown {
          position: static !important;
        }

        .dropdown-toggle {
          &.active {
            transform: rotate(180deg);
          }
        }
      }

      .mobile-menu {
        a {
          border-bottom: 1px solid #141414;
          background: #090909;
          padding: 18px 35px;
          color: #9a9a9a;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.014px;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .twice-btnssssss {
    display: flex;
    align-items: center;
    gap: 5px;

    .btn-launch {
      display: flex !important;
    }
  }
  .main-navbar .left-links {
    flex-direction: column;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 18px;
  }
  .main-navbar .navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-navbar .dropdown:hover .dropdown-menu {
    visibility: hidden;
    pointer-events: none;
    max-height: 0;
  }
  .main-navbar .navbar-nav .nav-item .dropdown-menu {
    &[data-bs-popper="static"] {
      max-height: 350px;
      visibility: visible;
      pointer-events: visible;
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  .main-navbar .navbar-nav .nav-item .dropdown-menu {
    transform: unset;
    padding: 0;
  }
}

@media (max-width: 350px) {
  .twice-btnssssss {
    display: flex;
    align-items: center;
    gap: 0px;

    .btn-launch {
      display: flex !important;
    }
  }
}

@media (max-width: 330px) {
  .twice-btnssssss {
    display: flex;
    align-items: center;
    gap: 0px;

    .btn-launch {
      display: flex !important;
    }
  }

  .main-navbar .navbar-brand img {
    width: 50px;
  }

  .btn-launch h6 {
    padding: 8px;
    font-size: 10px;
  }
}

.market-menu {
  a {
    justify-content: flex-start !important;
  }
}

.set-font-normal {
  a {
    text-transform: none !important;

    span {
      color: #999;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 32%);
}

.refer-modal {
  .modal-dialog {
    max-width: 450px;
  }
  .modal-content {
    background: #f3fff5;
    padding: 30px 20px;
    border-radius: 19px;
    .modal-body {
      background-color: #2c803b;
      padding: 35px 21px 35px 21px;
      border-radius: 22px;
      .main-heading {
        margin-bottom: 20px;
        h5 {
          color: #fff;
          font-weight: 900;
          font-size: 25px;
          line-height: 34px;
          text-align: center;
        }
      }
      .main-content {
        background-color: #329744;
        padding: 26px 30px;
        border-radius: 20px;
        p {
          color: #2c803b;
          font-weight: 500;
          font-size: 12px;
          line-height: 13.58px;
          margin-bottom: 7px;
        }
        h6 {
          color: #fff;
          font-weight: 500;
          font-size: 17px;
          line-height: 16px;
          margin-bottom: 16px;
        }
        a {
          color: #fff;
          font-weight: 500;
          font-size: 17px;
          line-height: 16px;
          word-break: break-all;
        }
      }
    }
  }
}
