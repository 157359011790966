.typesmain {
    padding-top: 136px;

    .typehead {
        max-width: 1240px;
        width: 100%;
        color: #FFFFFF;
        font-size: 90px;
        font-weight: 900;
        line-height: 90px;
        letter-spacing: -0.04em;
        text-align: left;
        margin: 0 auto 34px;
    }

    .innertypes {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        overflow: auto;
        padding: 0px 220px;

        &::-webkit-scrollbar{
            display: none;
        }

        .typecard {
            min-width: 490px;
            height: 747px;
            padding: 45px;
            border-radius: 20px;
            background: #22612D;

            .typecardhead {
                color: #FFFFFF;
                font-size: 50px;
                font-weight: 900;
                line-height: 50px;
                letter-spacing: -0.04em;
                text-align: left;
                margin-bottom: 27px;
            }

            .typecardpara {
                color: #FFFFFF;
                font-size: 28px;
                font-weight: 500;
                line-height: 30.32px;
                letter-spacing: -0.04em;
                text-align: left;
                margin-bottom: 27px;
            }

            .typecardupper{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                .typecardimgthree{
                    margin-top: 60px;
                }
            }
        }
    }
}
@media(max-width:1250px){
    .typesmain .innertypes{
        padding: 0px 15px 0px;
    }
    .typesmain .typehead{
        padding: 0px 15px;
    }
}

@media(max-width:991px){
    .typesmain{
        padding-top: 50px;
    }
    .typesmain .typehead{
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .typesmain .innertypes .typecard{
        padding: 30px;
        min-width: 300px;
        height: 540px;
    }
    .typesmain .innertypes .typecard .typecardhead{
        font-size: 30px;
        line-height: 30px;
    }
    .typesmain .innertypes .typecard .typecardpara{
        font-size: 20px;
        line-height: 20px;
    }
    
}