.mainsecurity {
    padding: 136px 15px 0px;
    max-width: 1270px;
    margin: 0 auto;
    width: 100%;

    .securityhead {
        color: #FFFFFF;
        font-size: 90px;
        font-weight: 900;
        line-height: 90px;
        letter-spacing: -0.04em;
        text-align: left;
        margin-bottom: 34px;
    }

    .securitycards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 23px;

        .innercard {
            width: 100%;
            padding: 55px 50px 55px 50px;
            border-radius: 21px;
            background: #22612D;

            .innercardhead {
                color: #FFFFFF;
                font-size: 43.17px;
                font-weight: 900;
                line-height: 43.17px;
                letter-spacing: -0.04em;
                text-align: left;
            }
        }
    }
}

@media(max-width:1250px){
    .mainsecurity .securitycards .innercard .innercardhead{
        font-size: 30px;
    }
    .mainsecurity .securitycards{
        grid-template-columns: 1fr;
    }
}

@media(max-width:991px){
    .mainsecurity{
        padding: 50px 15px 0px;
    }
    .mainsecurity .securityhead{
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .mainsecurity .securitycards .innercard{
        padding: 30px;
    }
    .mainsecurity .securitycards .innercard .innercardhead{
        font-size: 34px;
        line-height: 34px;
    }
}